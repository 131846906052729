import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AccountAmcInstancePage from './components/AccountAmcInstancePage';

const AccountAmcInstance = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Account AMC Instance</h3>
        <h3 className="page-subhead subhead">Setup and configure this accounts Amazon AMC Instance</h3>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
    <Row>
      <AccountAmcInstancePage />
    </Row>
  </Container>
);

export default AccountAmcInstance;
