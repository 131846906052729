import { useFetchReturnsArray as useFetch } from '../../../../shared/components/hooks/useFetch';
import config from '../../../../config';

function formatUrl(account, start, end, view) {
  if (account && account.value && start && end && view) {
    const url = `${config.serverUrl}/walmart/query/ad-group-by-dates?accountId=${account.value}&parentAccountId=${account.parentAccountId}&startDate=${start.format('YYYYMMDD')}&endDate=${end.format('YYYYMMDD')}&dataGroup=${view.value}`;
    return url;
  }
  return undefined;
}

export default function useAdGroupByDates(account, start, end, view) {
  return useFetch(formatUrl(account, start, end, view));
}
