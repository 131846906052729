import {
  useState, useEffect,
} from 'react';
import {
  when,
} from '../../../../../shared/functions';
import { useFetchReturnsArray as useFetch } from '../../../../../shared/components/hooks/useFetch';
import config from '../../../../../config';

function formatUrl(account) {
  if (account && account.value) {
    const url = `${config.serverUrl}/accounts/amc/instance?accountId=${account.value}`;
    return url;
  }
  return undefined;
}

function useAccountApiStatus(account) {
  return useFetch(formatUrl(account));
}

export default function useData(
  accountSelect,
) {
  const [apiStatusLoading, apiStatusResults, apiStatusError] = useAccountApiStatus(accountSelect);
  const loading = apiStatusLoading;
  // const [results, setResults] = useState(null);
  const [error, setError] = useState('');

  // useEffect(() => {
  //   setResults(apiStatusLoading);

  //   // return (() => setResults({
  //   //   ...apiStatusResults,
  //   // }));
  // }, [apiStatusResults, setResults]);

  useEffect(() => {
    console.log(apiStatusError);
    when(apiStatusError, () => {
      setError(apiStatusError);
    });
  }, [apiStatusError, setError]);

  return [loading, apiStatusResults, error];
}
