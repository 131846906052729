import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../../scss/app.scss';
import { getLocalStorage } from '../storage';
import BackgroundImage from '../img/logo/adjuggernaut/logo_light.png';
import BackgroundImageDark from '../img/logo/adjuggernaut/logo_dark.png';
import AdRevBckgroundLight from '../img/logo/adrev/logo_light.png';
import AdRevBckgroundDark from '../img/logo/adrev/logo_dark.png';

const currentDomain = window.location.hostname;
const cookieSetting = getLocalStorage('devDomain') || 'limitless';

const isDevelopment = () => currentDomain.startsWith('localhost');
const isAdJuggernaut = () => {
  if (currentDomain.endsWith('adjuggernaut.com') || (isDevelopment() && cookieSetting === 'adjuggernaut')) {
    return true;
  }
  return false;
}
const isAdRev = () => {
  if (currentDomain.endsWith('adrev.tech') || (isDevelopment() && cookieSetting === 'adrev')) {
    return true;
  }
  return false;
}
const isLimitless = () => {
  if (currentDomain.endsWith('limitless.tech') || (isDevelopment() && cookieSetting === 'limitless')) {
    return true;
  }
  return false;
}
const isPrimaryDomain = isLimitless;

const setFavicon = () => {
  if (isAdRev()) {
    document.getElementById('favicon').href = '/fav-ar.ico';
  }
  if (isAdJuggernaut()) {
    document.getElementById('favicon').href = '/fav-aj.ico';
  }
};

const getPlatform = (platform) => {
  if (platform === 'adrev' || (isAdRev() && !platform)) {
    const adRevPlafromSettings = {
      nameKey: 'adrev',
      cssKey: 'ar-',
      dashboardUrl: 'https://dashboard.adrev.tech',
      corprateUrl: 'https://adrev.tech',
      agencies: [{
        id: 5,
        name: 'adrev',
      }],
      colorPrimary: styles.arColorPrimary,
      colorAccent: styles.arColorAccent,
      lightColorBackground: styles.arLightColorBackground,
      lightColorText: styles.arLightColorText,
      darkColorBackground: styles.arDarkColorBackground,
      darkColorText: styles.arDarkColorText,
      limitlessColorBackground: styles.arLimitlessColorBackground,
      limitlessColorText: styles.arLimitlessColorText,
      darkColorBackgroundAlt: styles.arDarkColorBackgroundAlt,
      limitlessColorBackgroundAlt: styles.arLimitlessColorBackgroundAlt,
    }
    return adRevPlafromSettings;
  }
  if (platform === 'adjuggernaut' || (isAdJuggernaut() && !platform)) {
    const adJuggernautPlafromSettings = {
      nameKey: 'adjuggernaut',
      cssKey: 'aj-',
      dashboardUrl: 'https://dashboard.adjuggernaut.com',
      corprateUrl: 'https://adjuggernaut.com',
      agencies: [{
        id: 1,
        name: 'adjuggernaut',
      }, {
        id: 2,
        name: 'citiesms',
      }, {
        id: 3,
        name: 'hawkemedia',
      }, {
        id: 4,
        name: 'cartagent',
      }, {
        id: 6,
        name: 'voyageurgroup',
      }, {
        id: 7,
        name: 'altus',
      }],
      colorPrimary: styles.ajColorPrimary,
      colorAccent: styles.ajColorAccent,
      lightColorBackground: styles.ajLightColorBackground,
      lightColorText: styles.ajLightColorText,
      darkColorBackground: styles.ajDarkColorBackground,
      darkColorText: styles.ajDarkColorText,
      limitlessColorBackground: styles.ajLimitlessColorBackground,
      limitlessColorText: styles.ajLimitlessColorText,
      darkColorBackgroundAlt: styles.ajDarkColorBackgroundAlt,
      limitlessColorBackgroundAlt: styles.ajLimitlessColorBackgroundAlt,
    }
    return adJuggernautPlafromSettings;
  }
  const limitlessPlafromSettings = {
    nameKey: 'limitless',
    dashboardUrl: 'https://dashboard.limitless.tech',
    corprateUrl: 'https://limitless.tech',
    agencies: [{
      id: 0,
      name: 'limitless',
    }, {
      id: 6,
      name: 'voyageurgroup',
    }, {
      id: 7,
      name: 'altus',
    }],
    cssKey: '',
    colorPrimary: styles.colorPrimary,
    colorAccent: styles.colorAccent,
    lightColorBackground: styles.lightColorBackground,
    lightColorText: styles.lightColorText,
    darkColorBackground: styles.darkColorBackground,
    darkColorText: styles.darkColorText,
    limitlessColorBackground: styles.limitlessColorBackground,
    limitlessColorText: styles.limitlessColorText,
    darkColorBackgroundAlt: styles.darkColorBackgroundAlt,
    limitlessColorBackgroundAlt: styles.limitlessColorBackgroundAlt,

  }
  return limitlessPlafromSettings;
};

const defaultAgencyCode = getPlatform().nameKey;
const platform = getPlatform().nameKey;

const getCorprateUrl = () => {
  if (isAdRev()) {
    return 'https://adrev.tech';
  }
  if (isAdJuggernaut()) {
    return 'https://adjuggernaut.com';
  }
  return 'https://limitless.tech';
};

const getDashboardUrl = () => {
  if (isDevelopment()) {
    return 'http://localhost:3000';
  }
  if (isAdRev()) {
    return 'https://dashboard.adrev.tech';
  }
  if (isAdJuggernaut()) {
    return 'https://dashboard.adjuggernaut.com';
  }
  return 'https://dashboard.limitless.tech';
};

const LoginHeader = () => {
  if (isAdRev()) {
    return (
      <div className="account__head">
        <h3 className="account__title">
          <span className="account__logo" />
        </h3>
      </div>
    );
  }
  if (isAdJuggernaut()) {
    return (<span />);
  }
  return (
    <div className="account__head">
      <h3 className="account__title">
        <span className="account__logo" />
      </h3>
    </div>
  );
};

const SiteName = () => {
  if (isAdRev()) {
    return 'AdRev';
  }
  if (isAdJuggernaut()) {
    return 'AdJuggernaut';
  }
  return 'Limitless';
};

const SiteNameWithTM = () => {
  if (isAdRev()) {
    return (<span>AdRev</span>);
  }
  if (isAdJuggernaut()) {
    return (<span>AdJuggernaut</span>);
  }
  return (<span>Limitless</span>);
};

const SiteImage = ({
  logoWidth, logoHeight, link, type, margin,
}) => {
  const theme = useSelector(state => state.theme);
  const sellerSelect = useSelector(state => state.sellerSelect);
  const mediaMatch = window.matchMedia('(max-width: 768px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addEventListener('change', handler);
    return () => mediaMatch.removeEventListener('change', handler);
  });

  let backgroundImage = !theme.className.endsWith('light') ? BackgroundImageDark : BackgroundImage;

  let useAgencyLogo = false;
  if (sellerSelect && sellerSelect.agency) {
    try {
      const agency = JSON.parse(sellerSelect.agency);
      if (agency.id && agency.id > 1) {
        // Check to see if we have a agency logo we would like to add.
        getPlatform().agencies.forEach(checkAgency => {
          if (checkAgency.id === agency.id) {
            useAgencyLogo = true;
          }
        });
        // eslint-disable-next-line
        backgroundImage = !theme.className.endsWith('light') ? require(`../img/logo/${agency.code}/logo_dark.png`) : require(`../img/logo/${agency.code}/logo_light.png`);
      }
    } catch (error) {
      // default to base image.
    }
  }

  const customLogoStyle = {
    width: `${logoWidth}px`,
    height: `${logoHeight}px`,
    margin: margin || 'auto 0',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'center',
    backgroundPositionX: 'left',
    backgroundSize: 'contain',
    backgroundImage: `url(${backgroundImage})`,
    display: 'block',
    textAlign: 'right',
  };

  if (isAdRev()) {
    backgroundImage = !theme.className.endsWith('light') ? AdRevBckgroundDark : AdRevBckgroundLight;
    customLogoStyle.backgroundImage = `url(${backgroundImage})`;
    if (link) {
      if (type === 'special') {
        setFavicon();
        customLogoStyle.marginLeft = '300px';
      }
      if (matches) {
        customLogoStyle.display = 'none';
      }
      return (<Link style={customLogoStyle} className="onboarding onboarding__top_image" to={link} />);
    }
    customLogoStyle.width = '100%';
    return (<span style={customLogoStyle} />);
  }
  if (isAdJuggernaut()) {
    if (link) {
      if (type === 'special') {
        setFavicon();
        customLogoStyle.marginLeft = '300px';
      }
      if (matches) {
        customLogoStyle.display = 'none';
      }
      return (<Link style={customLogoStyle} to={link} />);
    }
    customLogoStyle.width = '100%';
    return (<span style={customLogoStyle} />);
  }
  if (useAgencyLogo) {
    if (link) {
      return (<Link style={customLogoStyle} to={link} />);
    }
    return (<span style={customLogoStyle} />);
  }
  if (link) {
    if (type === 'special') {
      return (<Link className="card__logo" style={{ height: `${logoHeight}px` }} to={link} />);
    }
    return (<Link className="topbar__logo" to={link} />);
  }
  return (<span className="card__logo" />);
};

const SiteImageOnboarding = ({
  logoWidth, logoHeight, link, logoImage,
}) => {
  const customLogoStyle = {
    width: `${logoWidth}px`,
    height: `${logoHeight}px`,
    margin: 'auto 0',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'center',
    backgroundPositionX: 'left',
    backgroundSize: 'contain',
    backgroundImage: `url(${logoImage})`,
    display: 'block',
    textAlign: 'right',
  };

  if (isAdRev()) {
    if (link) {
      setFavicon();
      delete customLogoStyle.margin;
      return (<Link className="onboarding__top_image" style={customLogoStyle} to={link} />);
    }
    return (<span style={customLogoStyle} />);
  }
  if (isAdJuggernaut()) {
    if (link) {
      setFavicon();
      delete customLogoStyle.margin;
      return (<Link className="onboarding__top_image" style={customLogoStyle} to={link} />);
    }
    return (<span style={customLogoStyle} />);
  }
  if (link) {
    return (<Link className="card__logo" style={{ height: `${logoHeight}px` }} to={link} />);
  }
  return (<span className="card__logo" />);
};

const getThemeName = () => {
  const theme = useSelector(state => state.theme);

  return theme.className;
}

SiteImageOnboarding.propTypes = {
  logoWidth: PropTypes.string,
  logoHeight: PropTypes.string,
  link: PropTypes.string,
  logoImage: PropTypes.string,
};

SiteImageOnboarding.defaultProps = {
  logoWidth: '900',
  logoHeight: '200',
  link: '',
  logoImage: null,
};

SiteImage.propTypes = {
  logoWidth: PropTypes.string,
  logoHeight: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
};

SiteImage.defaultProps = {
  logoWidth: '900',
  logoHeight: '200',
  link: '',
  type: '',
};

export {
  isDevelopment,
  isLimitless,
  isAdRev,
  setFavicon,
  getPlatform,
  getCorprateUrl,
  LoginHeader,
  SiteName,
  SiteNameWithTM,
  SiteImage,
  SiteImageOnboarding,
  defaultAgencyCode,
  platform,
  isPrimaryDomain,
  getDashboardUrl,
  getThemeName,
};
